//5_Anello di Costa Ariola
//12.7127    42.3519
/* Coord  12.7035    42.3542
mapOpenMap.js:164 Pitch  52.5000
mapOpenMap.js:165 Bearing  116.0000
mapOpenMap.js:166 Zoom  13.6076 */

export const track_1 = {
    track: [
        [42.3577194, 12.6938438],
        [42.3573706, 12.694788],
        [42.3571486, 12.6955605],
        [42.3572913, 12.6959896],
        [42.3574657, 12.6963973],
        [42.3573389, 12.6968479],
        [42.3572596, 12.6972771],
        [42.3572279, 12.6977706],
        [42.3573864, 12.6980281],
        [42.3576877, 12.6977921],
        [42.357989, 12.6980925],
        [42.3583219, 12.6983285],
        [42.3586708, 12.6985431],
        [42.3589562, 12.6986718],
        [42.359194, 12.6991653],
        [42.3594636, 12.6994228],
        [42.3598282, 12.6995945],
        [42.3602246, 12.6995945],
        [42.3605259, 12.6995945],
        [42.3607479, 12.6998305],
        [42.3609064, 12.7001953],
        [42.3610967, 12.7003455],
        [42.3613028, 12.7006245],
        [42.3616041, 12.700882],
        [42.3618102, 12.7013326],
        [42.3620797, 12.7013755],
        [42.362381, 12.701633],
        [42.3628407, 12.7018261],
        [42.3632054, 12.7020192],
        [42.3634591, 12.7023196],
        [42.3637445, 12.7024269],
        [42.3639981, 12.7026844],
        [42.3644104, 12.7026629],
        [42.3646799, 12.7027488],
        [42.3640616, 12.7030706],
        [42.3638079, 12.7033281],
        [42.3634432, 12.7033925],
        [42.3631103, 12.7036071],
        [42.362809, 12.703886],
        [42.3624761, 12.7039719],
        [42.3620639, 12.7041435],
        [42.3616675, 12.7042079],
        [42.3612711, 12.704165],
        [42.3609064, 12.7042079],
        [42.3605893, 12.7042937],
        [42.3604625, 12.7044868],
        [42.36051, 12.7050877],
        [42.36051, 12.7055383],
        [42.3604149, 12.7062035],
        [42.3603515, 12.7065039],
        [42.3603515, 12.7070832],
        [42.3603356, 12.7074909],
        [42.3603515, 12.7079201],
        [42.360399, 12.7083492],
        [42.360403, 12.7085102],
        [42.3604585, 12.7086174],
        [42.3604783, 12.7087677],
        [42.36051, 12.7089286],
        [42.3604664, 12.7090842],
        [42.3604783, 12.7091753],
        [42.3604189, 12.709288],
        [42.3603634, 12.7094489],
        [42.3602881, 12.7095938],
        [42.3602246, 12.7097011],
        [42.3601612, 12.7098405],
        [42.3601731, 12.7099693],
        [42.3601572, 12.7101409],
        [42.3601414, 12.7103341],
        [42.3601612, 12.7104628],
        [42.3601414, 12.7106291],
        [42.3601136, 12.7107847],
        [42.3601374, 12.7109939],
        [42.3601929, 12.7112567],
        [42.3602246, 12.7114445],
        [42.3602246, 12.7116001],
        [42.3602088, 12.7117342],
        [42.3602286, 12.7118844],
        [42.3601929, 12.7120024],
        [42.3601533, 12.7121741],
        [42.3601731, 12.7122867],
        [42.3601929, 12.7123618],
        [42.3601572, 12.7124637],
        [42.3601612, 12.7125442],
        [42.3601017, 12.7127159],
        [42.3600621, 12.7128178],
        [42.3599868, 12.7129197],
        [42.359963, 12.7131075],
        [42.3599551, 12.7132791],
        [42.3599868, 12.7134776],
        [42.3600264, 12.7135903],
        [42.3600819, 12.7137673],
        [42.3601691, 12.7139443],
        [42.3602405, 12.7140945],
        [42.3602999, 12.7141911],
        [42.3603673, 12.7142662],
        [42.3604783, 12.7143627],
        [42.3605536, 12.714411],
        [42.360625, 12.7144647],
        [42.3607003, 12.7146095],
        [42.3607637, 12.7147168],
        [42.3608232, 12.7148402],
        [42.360847, 12.7149636],
        [42.3608906, 12.7150923],
        [42.3609619, 12.715205],
        [42.3610134, 12.7153069],
        [42.3610927, 12.7154142],
        [42.3611601, 12.7154249],
        [42.3612077, 12.7156126],
        [42.3612513, 12.7158326],
        [42.3612711, 12.7160043],
        [42.3613187, 12.7161866],
        [42.3613781, 12.7163368],
        [42.3614178, 12.7164924],
        [42.3614455, 12.7166533],
        [42.3614772, 12.7168411],
        [42.3615168, 12.716943],
        [42.3615882, 12.7170342],
        [42.3616754, 12.7170825],
        [42.361723, 12.7171361],
        [42.3617983, 12.7172649],
        [42.3618894, 12.7173883],
        [42.3619965, 12.7174848],
        [42.3621114, 12.7175438],
        [42.3622383, 12.7176565],
        [42.3623889, 12.7178228],
        [42.3624919, 12.7179676],
        [42.3626426, 12.7181071],
        [42.3627179, 12.7182144],
        [42.3628407, 12.7183431],
        [42.3629874, 12.7184933],
        [42.3631024, 12.7186328],
        [42.3631816, 12.7187616],
        [42.3632332, 12.7189225],
        [42.3633005, 12.7190834],
        [42.363356, 12.7192444],
        [42.3634036, 12.7193516],
        [42.3635661, 12.7195019],
        [42.363689, 12.7196038],
        [42.3638475, 12.7197593],
        [42.3639189, 12.7198666],
        [42.3640259, 12.7200276],
        [42.3640972, 12.7201724],
        [42.3641646, 12.7203602],
        [42.3642003, 12.7205533],
        [42.364228, 12.7206981],
        [42.3642597, 12.7208966],
        [42.3643033, 12.7210253],
        [42.3643668, 12.7211541],
        [42.3643747, 12.7212614],
        [42.3643509, 12.7213579],
        [42.364343, 12.7215189],
        [42.3643826, 12.7216583],
        [42.3643588, 12.7218622],
        [42.3643469, 12.7220982],
        [42.364339, 12.7223343],
        [42.3643232, 12.7225059],
        [42.3643192, 12.722699],
        [42.3643549, 12.7228492],
        [42.3643905, 12.7229834],
        [42.3644421, 12.7230585],
        [42.3645768, 12.7231175],
        [42.3646878, 12.7231872],
        [42.3648186, 12.7232677],
        [42.36489, 12.7233106],
        [42.3649296, 12.7233857],
        [42.3648464, 12.723434],
        [42.3647671, 12.7235198],
        [42.3647433, 12.7236003],
        [42.3648028, 12.7236968],
        [42.3648979, 12.7237451],
        [42.3649811, 12.7237934],
        [42.3650604, 12.723847],
        [42.3651991, 12.7238846],
        [42.3652982, 12.7238685],
        [42.3653973, 12.7238578],
        [42.3654964, 12.7238953],
        [42.3655717, 12.7239329],
        [42.3656708, 12.7238631],
        [42.3657183, 12.7237773],
        [42.3657183, 12.7239543],
        [42.365754, 12.7241206],
        [42.3657897, 12.7242655],
        [42.3658254, 12.7244318],
        [42.3658214, 12.7245981],
        [42.3658135, 12.7247322],
        [42.3657461, 12.7249521],
        [42.3656747, 12.7251774],
        [42.3656232, 12.7253705],
        [42.365544, 12.7255422],
        [42.3654488, 12.7258158],
        [42.3653973, 12.7259606],
        [42.3653378, 12.7261752],
        [42.3652982, 12.7263737],
        [42.3652546, 12.7266043],
        [42.3652189, 12.7267975],
        [42.3651793, 12.7270228],
        [42.3651793, 12.7271622],
        [42.3652031, 12.7273822],
        [42.3652031, 12.7276075],
        [42.365211, 12.7277792],
        [42.365211, 12.7279454],
        [42.365108, 12.7279025],
        [42.364989, 12.7279508],
        [42.3648622, 12.7280045],
        [42.3647433, 12.7280742],
        [42.3645887, 12.7281386],
        [42.3644064, 12.7282137],
        [42.3642756, 12.7282619],
        [42.3641924, 12.728278],
        [42.3640893, 12.7283639],
        [42.3639427, 12.7284551],
        [42.3638277, 12.728557],
        [42.3637088, 12.7285677],
        [42.3635423, 12.7285463],
        [42.3634353, 12.7284336],
        [42.36336, 12.7282941],
        [42.3633005, 12.7281064],
        [42.3632649, 12.7278703],
        [42.3632411, 12.7276611],
        [42.3632332, 12.7275699],
        [42.3631341, 12.727527],
        [42.3630627, 12.7274036],
        [42.3629715, 12.7272588],
        [42.3628725, 12.727173],
        [42.3627654, 12.7271515],
        [42.3626663, 12.7270281],
        [42.3625435, 12.7269047],
        [42.3624682, 12.7267867],
        [42.3624325, 12.7266204],
        [42.3624483, 12.7264863],
        [42.3624682, 12.7263254],
        [42.3624166, 12.7262342],
        [42.3622898, 12.7262074],
        [42.362155, 12.7262557],
        [42.3620202, 12.7262342],
        [42.3618577, 12.7261859],
        [42.3616992, 12.7261645],
        [42.3615922, 12.7262181],
        [42.3614574, 12.7263147],
        [42.3613345, 12.7262771],
        [42.3611878, 12.7261966],
        [42.3610531, 12.7261859],
        [42.36095, 12.7262771],
        [42.3609143, 12.726422],
        [42.3608906, 12.72654],
        [42.3608826, 12.7267653],
        [42.3608113, 12.7269047],
        [42.3607082, 12.7269959],
        [42.3605655, 12.7271247],
        [42.3603832, 12.7272105],
        [42.3602207, 12.7273017],
        [42.3600542, 12.7274251],
        [42.3598996, 12.727586],
        [42.3598045, 12.7276719],
        [42.3596855, 12.7277738],
        [42.3594675, 12.7277309],
        [42.359305, 12.727645],
        [42.3591583, 12.7276343],
        [42.3589681, 12.7275324],
        [42.3587857, 12.7274251],
        [42.3585994, 12.7273446],
        [42.358429, 12.7272427],
        [42.3582427, 12.7271515],
        [42.3580524, 12.7270281],
        [42.3578581, 12.7269316],
        [42.3577432, 12.7268833],
        [42.3575529, 12.7268887],
        [42.3573666, 12.7269155],
        [42.3571724, 12.7269423],
        [42.3569662, 12.7269906],
        [42.356772, 12.7269959],
        [42.3565857, 12.7270067],
        [42.3564112, 12.7270228],
        [42.3562249, 12.7270389],
        [42.356106, 12.7270442],
        [42.3559237, 12.7270389],
        [42.3557651, 12.7270228],
        [42.3556026, 12.7270013],
        [42.3554083, 12.7270067],
        [42.3552577, 12.727012],
        [42.3550436, 12.727012],
        [42.3548652, 12.7270335],
        [42.354667, 12.7270979],
        [42.3545005, 12.7270871],
        [42.3543499, 12.7270174],
        [42.3542706, 12.726835],
        [42.3542547, 12.7266312],
        [42.3542785, 12.7264541],
        [42.35433, 12.7262717],
        [42.3544371, 12.7261698],
        [42.3545402, 12.7260035],
        [42.3546353, 12.7257997],
        [42.3547344, 12.7256012],
        [42.3548454, 12.725392],
        [42.3549405, 12.7251559],
        [42.355, 12.7250111],
        [42.3550436, 12.7248877],
        [42.3550555, 12.7247107],
        [42.3550793, 12.7246088],
        [42.3551744, 12.7244639],
        [42.3552497, 12.7242923],
        [42.355222, 12.724126],
        [42.3552141, 12.7239114],
        [42.3552458, 12.7237558],
        [42.3553171, 12.7236485],
        [42.3554519, 12.7235949],
        [42.3556263, 12.7235359],
        [42.3557849, 12.723434],
        [42.3559435, 12.723316],
        [42.3560069, 12.7231979],
        [42.3559435, 12.7229619],
        [42.3558602, 12.722742],
        [42.3557611, 12.7226079],
        [42.3556422, 12.7224523],
        [42.3555391, 12.7223289],
        [42.3554361, 12.7222055],
        [42.3554004, 12.7220017],
        [42.3554004, 12.7218407],
        [42.3554678, 12.7216691],
        [42.3555312, 12.7215457],
        [42.3556462, 12.7214599],
        [42.3557809, 12.7214652],
        [42.3559435, 12.721476],
        [42.3560981, 12.7214867],
        [42.3562765, 12.7214384],
        [42.3563795, 12.7213687],
        [42.3564945, 12.7212614],
        [42.3566253, 12.7211273],
        [42.3566848, 12.7209932],
        [42.3566848, 12.7208161],
        [42.3566887, 12.7205426],
        [42.3567046, 12.720328],
        [42.356768, 12.7201027],
        [42.3568354, 12.7199471],
        [42.3569583, 12.7196842],
        [42.3570812, 12.7194804],
        [42.3571525, 12.7193034],
        [42.3572477, 12.719062],
        [42.3573071, 12.7188045],
        [42.3573507, 12.7185953],
        [42.3573666, 12.7183807],
        [42.3573666, 12.7181447],
        [42.3573032, 12.7179676],
        [42.3572516, 12.7177638],
        [42.3571684, 12.7176136],
        [42.357101, 12.7174526],
        [42.3570653, 12.7173239],
        [42.3570257, 12.7171093],
        [42.3570019, 12.7169538],
        [42.3569464, 12.7167231],
        [42.3569187, 12.7165192],
        [42.3568671, 12.7163583],
        [42.3568077, 12.7162564],
        [42.3567046, 12.7162993],
        [42.3566451, 12.7164441],
        [42.3566055, 12.7166587],
        [42.3565579, 12.7168304],
        [42.3564945, 12.7170932],
        [42.356443, 12.7173561],
        [42.3564073, 12.7175868],
        [42.3563676, 12.7177745],
        [42.3563478, 12.7180374],
        [42.3563518, 12.7181607],
        [42.3563359, 12.7183324],
        [42.3563359, 12.7184826],
        [42.3562765, 12.718547],
        [42.3561774, 12.7184772],
        [42.3560862, 12.7183431],
        [42.3560029, 12.7182251],
        [42.3558919, 12.7181554],
        [42.3557928, 12.7181983],
        [42.3556739, 12.7182251],
        [42.3555867, 12.7180535],
        [42.3555233, 12.7178711],
        [42.3554836, 12.7177048],
        [42.3554242, 12.7175975],
        [42.3553171, 12.7175385],
        [42.3551824, 12.717399],
        [42.3551744, 12.717222],
        [42.3551705, 12.7170986],
        [42.3551546, 12.7169538],
        [42.355107, 12.7168465],
        [42.3549683, 12.7168143],
        [42.3548731, 12.7167553],
        [42.354774, 12.716648],
        [42.3547146, 12.7164871],
        [42.3546313, 12.7163851],
        [42.3545124, 12.7163905],
        [42.3543776, 12.7164763],
        [42.3542309, 12.7165997],
        [42.3541001, 12.7166533],
        [42.3539812, 12.7167124],
        [42.3538543, 12.7167982],
        [42.3537275, 12.7168787],
        [42.3536046, 12.7170289],
        [42.3534857, 12.7171469],
        [42.3533984, 12.7172703],
        [42.3532914, 12.717399],
        [42.3531804, 12.7173722],
        [42.3530694, 12.7172595],
        [42.3529465, 12.7172112],
        [42.3527998, 12.7171952],
        [42.3526254, 12.7171683],
        [42.3524827, 12.717104],
        [42.3523043, 12.7170235],
        [42.3521933, 12.7169752],
        [42.3520783, 12.7169698],
        [42.3518761, 12.7169216],
        [42.351777, 12.7168626],
        [42.3516303, 12.7168143],
        [42.3516065, 12.7166909],
        [42.3516422, 12.7164978],
        [42.3517017, 12.7162564],
        [42.351781, 12.7160525],
        [42.3518603, 12.7158648],
        [42.3519395, 12.7156556],
        [42.3520188, 12.7154732],
        [42.3520823, 12.7153122],
        [42.3521378, 12.7152157],
        [42.3521417, 12.7151138],
        [42.3520981, 12.7150708],
        [42.3519395, 12.715103],
        [42.3517572, 12.7151138],
        [42.3516224, 12.7151352],
        [42.3514599, 12.715103],
        [42.3513647, 12.7151111],
        [42.3512259, 12.7151084],
        [42.3511149, 12.7150977],
        [42.3510277, 12.715095],
        [42.3509524, 12.7150708],
        [42.350881, 12.7150521],
        [42.3509068, 12.7149448],
        [42.3509524, 12.7147624],
        [42.3510238, 12.7145129],
        [42.3510713, 12.7143654],
        [42.3511268, 12.7142045],
        [42.3511903, 12.713998],
        [42.3512696, 12.7137753],
        [42.3513231, 12.7136198],
        [42.351333, 12.7134535],
        [42.3513409, 12.713314],
        [42.3513409, 12.7131531],
        [42.3513449, 12.7130055],
        [42.3513528, 12.7128688],
        [42.3513568, 12.7127212],
        [42.3514063, 12.7125791],
        [42.351448, 12.7124342],
        [42.3515015, 12.7122813],
        [42.3515411, 12.7121526],
        [42.3515629, 12.7120775],
        [42.3515986, 12.7119192],
        [42.3516343, 12.7117637],
        [42.3516501, 12.7116618],
        [42.3516204, 12.7115116],
        [42.3515887, 12.7113613],
        [42.3515629, 12.7112514],
        [42.3515411, 12.7111253],
        [42.3515788, 12.7110207],
        [42.3516482, 12.7108786],
        [42.3517076, 12.7107444],
        [42.351779, 12.7105674],
        [42.3518365, 12.7104333],
        [42.351886, 12.7103126],
        [42.3519217, 12.7102214],
        [42.3519673, 12.7101034],
        [42.351995, 12.7100095],
        [42.3520307, 12.7099344],
        [42.3520743, 12.7098057],
        [42.3521556, 12.7097681],
        [42.3522864, 12.7097091],
        [42.3523955, 12.7096421],
        [42.3524866, 12.7095938],
        [42.3525778, 12.7095321],
        [42.352669, 12.7094623],
        [42.35278, 12.7093846],
        [42.3527959, 12.7092156],
        [42.3528157, 12.7090117],
        [42.3528335, 12.7088401],
        [42.3528395, 12.7087167],
        [42.3528989, 12.7085853],
        [42.3529743, 12.7084538],
        [42.3530377, 12.7082956],
        [42.3530912, 12.7081883],
        [42.353115, 12.7080327],
        [42.3531269, 12.7078798],
        [42.3531467, 12.7077404],
        [42.3531645, 12.7075633],
        [42.353111, 12.7073863],
        [42.3530773, 12.7072629],
        [42.3530317, 12.70711],
        [42.3529881, 12.7069518],
        [42.3529505, 12.7068231],
        [42.3529267, 12.7067292],
        [42.3528177, 12.7066541],
        [42.3527186, 12.7065763],
        [42.3526234, 12.7065092],
        [42.3525263, 12.7064261],
        [42.3524192, 12.7064073],
        [42.3522904, 12.7063939],
        [42.3521734, 12.7063778],
        [42.3520545, 12.7063644],
        [42.3519336, 12.7063403],
        [42.3518226, 12.7063295],
        [42.3517611, 12.7062249],
        [42.3516739, 12.7060962],
        [42.3516046, 12.7060372],
        [42.3514836, 12.7059406],
        [42.3513865, 12.7058735],
        [42.3512795, 12.7058333],
        [42.3511586, 12.7057797],
        [42.3510614, 12.7057421],
        [42.3509722, 12.7057099],
        [42.3508652, 12.7056643],
        [42.3507661, 12.7056697],
        [42.3506491, 12.7056804],
        [42.35055, 12.7055705],
        [42.350443, 12.7054229],
        [42.3503637, 12.7052942],
        [42.3502883, 12.7051681],
        [42.3501912, 12.7050179],
        [42.3500762, 12.7049187],
        [42.3499514, 12.7048248],
        [42.3498205, 12.7047578],
        [42.3497155, 12.7047095],
        [42.3496005, 12.7047363],
        [42.3494875, 12.7047685],
        [42.3493309, 12.704798],
        [42.3491743, 12.7048329],
        [42.3490078, 12.7048624],
        [42.348873, 12.7048865],
        [42.3487342, 12.7048597],
        [42.3486193, 12.7048382],
        [42.3485221, 12.7048114],
        [42.348425, 12.7047899],
        [42.3483913, 12.7046451],
        [42.3483497, 12.7045003],
        [42.3483041, 12.7043179],
        [42.3482743, 12.7042106],
        [42.3481851, 12.7040657],
        [42.3481078, 12.7039263],
        [42.3480206, 12.7037868],
        [42.347971, 12.7037251],
        [42.3478481, 12.703599],
        [42.3477451, 12.7035078],
        [42.3476103, 12.7033603],
        [42.3474953, 12.7032584],
        [42.3473565, 12.7031296],
        [42.3471841, 12.7029741],
        [42.3470532, 12.7028427],
        [42.346964, 12.7027568],
        [42.3468629, 12.7026522],
        [42.3468054, 12.7024913],
        [42.346744, 12.7023116],
        [42.3466647, 12.7022231],
        [42.3465616, 12.7020916],
        [42.3464744, 12.7019978],
        [42.3463891, 12.7018851],
        [42.3463078, 12.7017832],
        [42.3462761, 12.7016705],
        [42.3462622, 12.7014613],
        [42.3462523, 12.7012414],
        [42.3462345, 12.7011126],
        [42.3462285, 12.7009946],
        [42.3462147, 12.7007693],
        [42.346179, 12.7005118],
        [42.3462305, 12.7003831],
        [42.3463059, 12.7001846],
        [42.3463693, 12.700029],
        [42.346183, 12.6997876],
        [42.3459609, 12.6997125],
        [42.3458261, 12.6994389],
        [42.3457666, 12.6991332],
        [42.3457508, 12.6988113],
        [42.3457072, 12.6985323],
        [42.3456239, 12.6981997],
        [42.3455089, 12.6978511],
        [42.3454614, 12.697497],
        [42.3454019, 12.6972127],
        [42.3452235, 12.6969337],
        [42.3450451, 12.6967084],
        [42.3449776, 12.6965582],
        [42.3450371, 12.6963383],
        [42.3450807, 12.6960969],
        [42.3450688, 12.6959521],
        [42.3449578, 12.6958984],
        [42.3450133, 12.6957589],
        [42.3451204, 12.6956731],
        [42.3452433, 12.6955551],
        [42.3453384, 12.6952976],
        [42.3453384, 12.6950884],
        [42.3452155, 12.6949275],
        [42.3449737, 12.6948255],
        [42.3447635, 12.6947558],
        [42.3445653, 12.6947236],
        [42.3444027, 12.6948148],
        [42.3442005, 12.6949489],
        [42.3439904, 12.695083],
        [42.3438278, 12.6953191],
        [42.3436732, 12.6951528],
        [42.3434551, 12.6949221],
        [42.3432965, 12.6947558],
        [42.34313, 12.6945251],
        [42.343015, 12.6943535],
        [42.3428842, 12.6941925],
        [42.3429436, 12.6939243],
        [42.3428842, 12.6936454],
        [42.3428762, 12.6933932],
        [42.342908, 12.6929641],
        [42.3429199, 12.6926154],
        [42.3429595, 12.6922882],
        [42.3429754, 12.692036],
        [42.3430071, 12.6917678],
        [42.3429516, 12.6916766],
        [42.3429833, 12.6914674],
        [42.3430705, 12.6913226],
        [42.3432331, 12.6913494],
        [42.3433441, 12.6914352],
        [42.343467, 12.6916444],
        [42.3435503, 12.6918644],
        [42.3436534, 12.6920682],
        [42.3437049, 12.6923043],
        [42.3438318, 12.6924652],
        [42.3440538, 12.6922935],
        [42.3442243, 12.6922023],
        [42.3444067, 12.692095],
        [42.3446248, 12.6919609],
        [42.3448587, 12.6917142],
        [42.3450768, 12.6915479],
        [42.3453067, 12.6913494],
        [42.3455367, 12.6912153],
        [42.345727, 12.6912045],
        [42.3459173, 12.6911402],
        [42.3460878, 12.6910865],
        [42.3462979, 12.691049],
        [42.346512, 12.6910114],
        [42.3467182, 12.6910383],
        [42.3469164, 12.6910973],
        [42.3471067, 12.6911938],
        [42.3472772, 12.6913226],
        [42.3474556, 12.6913816],
        [42.3476142, 12.6912743],
        [42.3477887, 12.6912367],
        [42.3479473, 12.691285],
        [42.3480741, 12.691285],
        [42.3481336, 12.6913387],
        [42.3483477, 12.6913226],
        [42.348542, 12.6913118],
        [42.3488036, 12.6912367],
        [42.3489939, 12.6911402],
        [42.3491247, 12.6909953],
        [42.3492754, 12.6908183],
        [42.3493626, 12.6906842],
        [42.3495172, 12.6904321],
        [42.3496758, 12.6902014],
        [42.349771, 12.690019],
        [42.3499335, 12.6897669],
        [42.3500326, 12.6897615],
        [42.3501159, 12.689783],
        [42.350211, 12.6898313],
        [42.3502745, 12.6900083],
        [42.3503696, 12.6901799],
        [42.350441, 12.6901317],
        [42.3504727, 12.6900619],
        [42.3505004, 12.6899761],
        [42.3505203, 12.6898956],
        [42.350552, 12.6897776],
        [42.3506313, 12.689622],
        [42.3507542, 12.6894611],
        [42.3510357, 12.6892197],
        [42.3513449, 12.6890159],
        [42.3517056, 12.6888174],
        [42.3523637, 12.6885116],
        [42.3525421, 12.688356],
        [42.352562, 12.6884741],
        [42.3525144, 12.6886618],
        [42.352451, 12.688871],
        [42.3523915, 12.6890212],
        [42.3523162, 12.68915],
        [42.3522369, 12.6892787],
        [42.3521616, 12.6895845],
        [42.3521695, 12.6898205],
        [42.3521814, 12.6900887],
        [42.3520426, 12.6899868],
        [42.3518959, 12.6898742],
        [42.3517968, 12.6901746],
        [42.3516739, 12.6903141],
        [42.3515946, 12.6905394],
        [42.3513449, 12.690829],
        [42.3512259, 12.6910651],
        [42.3512497, 12.6914352],
        [42.351448, 12.6914191],
        [42.3516145, 12.6913977],
        [42.3518048, 12.6914299],
        [42.3520981, 12.6915908],
        [42.3522884, 12.6918054],
        [42.3525659, 12.6921272],
        [42.3530416, 12.6925135],
        [42.3535094, 12.6926637],
        [42.3539534, 12.693007],
        [42.354445, 12.6935112],
        [42.3547542, 12.6938438],
        [42.3548969, 12.6939726],
        [42.3551506, 12.6940155],
        [42.355559, 12.6939994],
        [42.3558483, 12.6940155],
        [42.3564826, 12.6939297],
        [42.3571961, 12.6938653],
        [42.3576243, 12.6938653]
    ].map(x => x.reverse()),
    coord: [12.7048,42.3543],
    pitch: 52.5,
    bearing:116,
    zoom:12.6376,
    name:"Anello dei Monti Aspresi con Partenza da Santa Maria",
    info: {
        difficolta:"Escurionistica",
        tipologia:"anello",
        dislivello:"500 mt +/-",
        durata:"6h ritorno",
        distanza:"12 km",
        centerPos:[12.7048,42.3543],

    }
}
//1_Da Poggio Perugino a Fonte Cognolo
// 12.7334    42.3510
/* Coord  12.7389    42.3488
mapOpenMap.js:164 Pitch  41.5000
mapOpenMap.js:165 Bearing  -34.0000
mapOpenMap.js:166 Zoom  13.9508 */
export const track_2 = {
    track: [
        [42.350108, 12.7549982],
        [42.35033, 12.7545047],
        [42.3501873, 12.7540541],
        [42.3499335, 12.7537107],
        [42.3500128, 12.7535391],
        [42.3504251, 12.7536678],
        [42.3509009, 12.7536035],
        [42.3510753, 12.7532816],
        [42.3511229, 12.7531099],
        [42.3509326, 12.7531314],
        [42.3508533, 12.7530026],
        [42.3510594, 12.7525306],
        [42.3511229, 12.75208],
        [42.3509326, 12.7516294],
        [42.3509009, 12.751286],
        [42.3508374, 12.7508569],
        [42.3505996, 12.7504706],
        [42.3506789, 12.7500415],
        [42.3506154, 12.7496767],
        [42.3507423, 12.7494836],
        [42.350885, 12.7493978],
        [42.3507899, 12.7488184],
        [42.3510594, 12.7486682],
        [42.3512497, 12.7483463],
        [42.3513607, 12.7479815],
        [42.3511387, 12.7475524],
        [42.350885, 12.7473164],
        [42.3507899, 12.7469945],
        [42.3508691, 12.7465224],
        [42.350552, 12.7463293],
        [42.3505044, 12.7460718],
        [42.3506471, 12.7457285],
        [42.3506154, 12.7453852],
        [42.3508216, 12.7449775],
        [42.3507423, 12.7445912],
        [42.3505837, 12.7441621],
        [42.3504568, 12.7437329],
        [42.3502348, 12.7432609],
        [42.3498067, 12.7432823],
        [42.3494895, 12.7434969],
        [42.3490137, 12.74369],
        [42.3488552, 12.7436471],
        [42.348982, 12.743175],
        [42.3489979, 12.7425742],
        [42.3488076, 12.7421236],
        [42.3487124, 12.7416086],
        [42.3483318, 12.7412224],
        [42.3481098, 12.740922],
        [42.3476182, 12.7407718],
        [42.3472217, 12.740407],
        [42.3465715, 12.7403212],
        [42.3462543, 12.739892],
        [42.3461433, 12.7394629],
        [42.3459371, 12.7391839],
        [42.3457468, 12.7392268],
        [42.3455089, 12.7393556],
        [42.3451283, 12.7392912],
        [42.3446367, 12.7390337],
        [42.3441767, 12.738862],
        [42.3435106, 12.7388406],
        [42.3430348, 12.7388191],
        [42.3425273, 12.7384758],
        [42.3421467, 12.7382612],
        [42.341655, 12.7380037],
        [42.3413854, 12.7375746],
        [42.3413537, 12.7370596],
        [42.3414647, 12.7365446],
        [42.3415123, 12.7362013],
        [42.3417978, 12.7360296],
        [42.3421943, 12.735858],
        [42.3424639, 12.7356434],
        [42.3427811, 12.7355146],
        [42.3431459, 12.7354288],
        [42.3434472, 12.7352786],
        [42.3436534, 12.7349567],
        [42.3438595, 12.7346992],
        [42.3437961, 12.7342916],
        [42.3437803, 12.7339697],
        [42.3439864, 12.733798],
        [42.3440499, 12.7333903],
        [42.3441767, 12.7331328],
        [42.3445257, 12.7331114],
        [42.3447635, 12.7331114],
        [42.3450173, 12.7329183],
        [42.3452869, 12.7325106],
        [42.3454772, 12.7321243],
        [42.3456517, 12.7318668],
        [42.3457627, 12.7316952],
        [42.3455089, 12.7314806],
        [42.3456041, 12.7310514],
        [42.3456992, 12.730794],
        [42.3460481, 12.7306437],
        [42.3464129, 12.7304292],
        [42.3465715, 12.7302146],
        [42.3467142, 12.7300644],
        [42.3469442, 12.7299356],
        [42.347079, 12.7298069],
        [42.3472138, 12.7296996],
        [42.3473327, 12.7295923],
        [42.3474437, 12.7295065],
        [42.347412, 12.7291417],
        [42.3473644, 12.7287984],
        [42.3473486, 12.7285194],
        [42.3473644, 12.7281976],
        [42.3473724, 12.7280045],
        [42.3474596, 12.7276719],
        [42.3474992, 12.7274466],
        [42.347531, 12.7271783],
        [42.3475548, 12.7269638],
        [42.3475706, 12.7267599],
        [42.3474992, 12.7265882],
        [42.3473327, 12.7265131],
        [42.3471662, 12.7265024],
        [42.3469918, 12.7265346],
        [42.3467697, 12.7264488],
        [42.3465319, 12.7264059],
        [42.3463257, 12.7263951],
        [42.3461354, 12.7264595],
        [42.3459451, 12.726438],
        [42.3458023, 12.7262986],
        [42.3456834, 12.7261162],
        [42.345501, 12.7258158],
        [42.3452631, 12.7255476],
        [42.3450728, 12.7253759],
        [42.3449459, 12.7252471],
        [42.3449539, 12.7249146],
        [42.3448825, 12.7246678],
        [42.3447556, 12.7243996],
        [42.3446446, 12.7240777],
        [42.3447001, 12.7236807],
        [42.3447794, 12.7233267],
        [42.3449459, 12.723155],
        [42.3451521, 12.7229941],
        [42.3453345, 12.7229404],
        [42.345501, 12.7228332],
        [42.3456675, 12.7227044],
        [42.3458182, 12.7224684],
        [42.3459768, 12.7222538],
        [42.3461988, 12.7220821],
        [42.3464129, 12.7220178],
        [42.3467301, 12.7219534],
        [42.3469759, 12.7218997],
        [42.3472455, 12.7218246],
        [42.3474675, 12.7216637],
        [42.347642, 12.7215242],
        [42.3478481, 12.7214277],
        [42.3480622, 12.7213097],
        [42.3482763, 12.7211487],
        [42.3484508, 12.7209663],
        [42.348649, 12.7208161],
        [42.3488789, 12.7206552],
        [42.3490851, 12.7205157],
        [42.3492516, 12.7204084],
        [42.349434, 12.7203226],
        [42.3496164, 12.7202475],
        [42.3496639, 12.7200115],
        [42.3497749, 12.7198184],
        [42.3498939, 12.719593],
        [42.3500049, 12.7193356],
        [42.3500842, 12.7191103],
        [42.3501714, 12.718842],
        [42.3503855, 12.718606],
        [42.3505599, 12.7185202],
        [42.350663, 12.7182949],
        [42.3508295, 12.7181447],
        [42.3510436, 12.7181125],
        [42.3512418, 12.7180481],
        [42.3514162, 12.7179086],
        [42.351551, 12.7177906],
        [42.351781, 12.7176082],
        [42.3519395, 12.7175224],
        [42.3520585, 12.7174044],
        [42.3519792, 12.7172112],
        [42.3519554, 12.7170503],
        [42.3519633, 12.7169323],
        [42.3520902, 12.7168894],
        [42.3522884, 12.7168465],
        [42.3525104, 12.7168572],
        [42.3526611, 12.7168036],
        [42.3528117, 12.7167499],
        [42.3530258, 12.7166748],
        [42.3531844, 12.7166212]
    ].map(x => x.reverse()),
    pos_center: [12.7328,42.3501],
    coord: [12.7328,42.3501],
    pitch: 41.5,
    bearing:-34,
    zoom:13.027,
    name:"Da Poggio Perugino a Fonte Cognolo",
    info: {
        difficolta:"Escurionistica",
        tipologia:"lineare a/r",
        dislivello:"300 mt +/-",
        durata:"2h30 andata,2h ritorno",
        distanza:"6 km andata,6 km ritorno",
        centerPos: [12.7328,42.3501],

    },
    desc: {
       
    }
}
//4_da Roccantica via monte pizzuto
//12.7161    42.3342
/* Coord  12.7125    42.3318
mapOpenMap.js:164 Pitch  53.5000
mapOpenMap.js:165 Bearing  -22.0000
mapOpenMap.js:166 Zoom  13.5303 */
export const track_3 = {
    track: [
        [42.3531368, 12.7166426],
        [42.3526531, 12.7167499],
        [42.3522567, 12.7168143],
        [42.3519316, 12.7169538],
        [42.3519554, 12.7171254],
        [42.3520506, 12.7174366],
        [42.3518523, 12.7175546],
        [42.3515114, 12.7177906],
        [42.351218, 12.7180803],
        [42.3509643, 12.7181232],
        [42.3506789, 12.718209],
        [42.350552, 12.7185416],
        [42.35033, 12.7186918],
        [42.3501397, 12.7189279],
        [42.3500525, 12.7192175],
        [42.3499415, 12.7194643],
        [42.3497987, 12.7197111],
        [42.3496084, 12.7200544],
        [42.3495688, 12.720269],
        [42.3494102, 12.7203655],
        [42.3491565, 12.7204943],
        [42.3488552, 12.7206337],
        [42.3485697, 12.7208269],
        [42.3482525, 12.7211487],
        [42.3480147, 12.7213311],
        [42.3476896, 12.7215135],
        [42.3474279, 12.7217174],
        [42.3471107, 12.721889],
        [42.3467301, 12.7219427],
        [42.3464208, 12.7219963],
        [42.3461512, 12.7221251],
        [42.3457785, 12.722522],
        [42.3455169, 12.7228439],
        [42.3452393, 12.7229834],
        [42.3449063, 12.7232087],
        [42.3446842, 12.7237236],
        [42.3446446, 12.7242172],
        [42.3447873, 12.7245069],
        [42.3448984, 12.7247965],
        [42.344938, 12.7251291],
        [42.3452393, 12.7255154],
        [42.3455565, 12.7258587],
        [42.3457468, 12.7262449],
        [42.3459451, 12.7264166],
        [42.3461592, 12.7264917],
        [42.346286, 12.7264166],
        [42.3466032, 12.7264166],
        [42.3469204, 12.7265024],
        [42.3472931, 12.7264917],
        [42.3475785, 12.7266526],
        [42.3475468, 12.7271891],
        [42.3474596, 12.7276397],
        [42.3473327, 12.7282298],
        [42.3473644, 12.7288735],
        [42.3474834, 12.7295065],
        [42.3471583, 12.7297425],
        [42.3469759, 12.7298927],
        [42.3467539, 12.7300537],
        [42.3465001, 12.7301073],
        [42.3462147, 12.730279],
        [42.3459926, 12.7304506],
        [42.3456675, 12.7305257],
        [42.3454693, 12.7306759],
        [42.3453345, 12.7307296],
        [42.3452076, 12.7307296],
        [42.3450728, 12.7307081],
        [42.3449301, 12.7305794],
        [42.344716, 12.7304077],
        [42.3445653, 12.7302039],
        [42.3443671, 12.7302575],
        [42.3440578, 12.7303648],
        [42.343923, 12.7304828],
        [42.3437168, 12.7305794],
        [42.3434234, 12.7307296],
        [42.3431776, 12.7308798],
        [42.342908, 12.7309549],
        [42.3425987, 12.7309763],
        [42.3422973, 12.7310085],
        [42.3420674, 12.7310514],
        [42.3417978, 12.7309442],
        [42.3416629, 12.7308691],
        [42.3415043, 12.730751],
        [42.3413061, 12.730751],
        [42.3410523, 12.7307725],
        [42.3408461, 12.7306223],
        [42.3405844, 12.7305043],
        [42.3403227, 12.7304506],
        [42.3401086, 12.7304399],
        [42.3399262, 12.7305579],
        [42.3396169, 12.7305794],
        [42.3394028, 12.7305579],
        [42.3392204, 12.7303219],
        [42.3390222, 12.7299464],
        [42.3388794, 12.7297211],
        [42.3387367, 12.7295923],
        [42.3384829, 12.7293777],
        [42.3382767, 12.7292061],
        [42.3380229, 12.7288091],
        [42.3378484, 12.7285194],
        [42.3375947, 12.7282405],
        [42.3374836, 12.7279723],
        [42.3374757, 12.7277148],
        [42.3373647, 12.7273607],
        [42.3371664, 12.7271461],
        [42.3369761, 12.7268565],
        [42.3368254, 12.7265882],
        [42.3366985, 12.7263737],
        [42.3364209, 12.7262771],
        [42.3361671, 12.7262235],
        [42.3358182, 12.7261376],
        [42.3355644, 12.7261055],
        [42.3353661, 12.7259767],
        [42.335152, 12.7257192],
        [42.3349854, 12.7254725],
        [42.3348902, 12.725172],
        [42.3348427, 12.7250862],
        [42.3348189, 12.7253759],
        [42.3347713, 12.7256548],
        [42.3347078, 12.7259338],
        [42.3346127, 12.7262342],
        [42.3345254, 12.7266634],
        [42.3343906, 12.726953],
        [42.3341685, 12.7271247],
        [42.3338989, 12.727232],
        [42.3337085, 12.7265239],
        [42.3333913, 12.7258158],
        [42.3331375, 12.7252793],
        [42.3328678, 12.7247429],
        [42.3325347, 12.7243137],
        [42.3323444, 12.7237773],
        [42.3323126, 12.7230048],
        [42.3323602, 12.722404],
        [42.3324078, 12.7214813],
        [42.3324395, 12.7212882],
        [42.3319478, 12.7209878],
        [42.3315036, 12.7216315],
        [42.3312181, 12.7222323],
        [42.3306629, 12.7226615],
        [42.3300918, 12.7231336],
        [42.3297746, 12.7234983],
        [42.3292352, 12.7234554],
        [42.3287752, 12.722919],
        [42.3282675, 12.7223182],
        [42.3279344, 12.7217817],
        [42.3274902, 12.7212238],
        [42.3270302, 12.7208591],
        [42.3267605, 12.7207732],
        [42.3265542, 12.7201939],
        [42.326586, 12.7196145],
        [42.3266811, 12.7193356],
        [42.3263797, 12.7187347],
        [42.3260624, 12.7187991],
        [42.3257928, 12.7184987],
        [42.3255389, 12.7180481],
        [42.3252058, 12.7177048],
        [42.3248885, 12.717104],
        [42.3244919, 12.7163744],
        [42.3247298, 12.7165031],
        [42.3249837, 12.7163744],
        [42.3251582, 12.7166748],
        [42.3251423, 12.7159882],
        [42.3249519, 12.7153015],
        [42.3251582, 12.7153659],
        [42.3253644, 12.7158594],
        [42.3257769, 12.7161384],
        [42.3259831, 12.71631],
        [42.3261259, 12.715795],
        [42.3262052, 12.7151942],
        [42.3265384, 12.7153015],
        [42.3268398, 12.7153015],
        [42.3269826, 12.7150655],
        [42.3267129, 12.7148938],
        [42.326586, 12.7146149],
        [42.3266494, 12.714057],
        [42.3267287, 12.7135205],
        [42.3267446, 12.7129626],
        [42.3265384, 12.7125549],
        [42.326348, 12.7120399],
        [42.3259831, 12.7110958],
        [42.3256817, 12.7105594],
        [42.3256024, 12.7098083],
        [42.3251899, 12.7091432],
        [42.3246981, 12.7086926],
        [42.3241904, 12.7078986],
        [42.3238573, 12.7070403],
        [42.3234289, 12.7060318],
        [42.3231592, 12.7053237],
        [42.3227943, 12.7045512],
        [42.3223818, 12.7040577],
        [42.3221439, 12.7034783],
        [42.322128, 12.7030277],
        [42.3218583, 12.7025986],
        [42.3222549, 12.7023625],
        [42.3225722, 12.702105],
        [42.3226833, 12.7019119],
        [42.3226357, 12.7017188],
        [42.3227943, 12.7012897],
        [42.3230323, 12.700839],
        [42.3231434, 12.7003241],
        [42.3227943, 12.7004743],
        [42.3222708, 12.7006888],
        [42.321779, 12.7005816],
        [42.3212395, 12.7003241],
        [42.3207953, 12.6999807],
        [42.3203669, 12.6997662],
        [42.320129, 12.6991653],
        [42.3199386, 12.6981354],
        [42.3200972, 12.697084],
        [42.3202559, 12.6963973],
        [42.3202559, 12.6957321],
        [42.3204145, 12.6951313]
    ].map(x => x.reverse()),
    pos_center: [12.7161,42.3342],
    coord: [12.7143 ,42.3389],
    pitch: 53.5,
    bearing:-22,
    zoom:12.6236,
    name:"Da Roccantica Via  Monte Pizzuto",
    info: {
        difficolta:"Escurionistica",
        tipologia:"lineare",
        dislivello:"850 mt +/-",
        durata:"3h30 andata,3h ritorno",
        distanza:"10 km",
        centerPos:[12.7143 ,42.3389]

    }
}
