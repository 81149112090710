import React, { useRef, useEffect, useState, Component } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
import { track_1, track_2 } from "./TrackConstants"

mapboxgl.accessToken = 'pk.eyJ1IjoidG9kYmVydHV6emkiLCJhIjoiY2wxYXJxYzA1MDlmcTNjcDhpZmV1bmNjcyJ9.KNvXN5O-p1xzhN6R5GKphg';


export default class OpenMapComp extends Component {
    constructor(props) {
        super(props);
        console.log("props", props)
        this.state = {
            coordinates: track_1,
           
        };
        this.mapContainer = React.createRef();
        this.map = React.createRef();
    }
    componentDidMount() {
        const pointersList = {
            type: 'FeatureCollection',
            features: [
                {
                    type: 'Feature',

                    geometry: {
                        type: 'Point',
                        coordinates: [12.71694569, 42.352336]
                        //coordinates: [12.71694569, 42.352536]
                    },
                    properties: {
                        iconSize: [54, 140],
                        title: 'Mapbox',
                        description: 'Washington, D.C.'
                    }

                },

            ]
        };
        const coordinatesRev = this.state.coordinates.map(x => x.reverse() );
        if (this.map.current) return; // initialize map only once
        this.map.current = new mapboxgl.Map({
            container: this.mapContainer.current,
            //style: 'mapbox://styles/mapbox/streets-v11',
            center: [12.7166212, 42.3531844],
            pitch: 28, // pitch in degrees
            //bearing: -60, // bearing in degrees
            zoom: 13,
            style: 'mapbox://styles/todbertuzzi/cl1ary226000j15phmkalhlzy', // custom style url from https://studio.mapbox.com/
            //style: 'mapbox://styles/mapbox/satellite-v9'
        });
        const scale = new mapboxgl.ScaleControl({
            maxWidth: 80,
            unit: 'metric'
        });
        this.map.current.addControl(scale);
        this.map.current.addControl(new mapboxgl.NavigationControl());
        this.map.current.on('load', () => {
            if (!this.map.current.getSource('route')) {
                console.log("ADD ROUTE")
                this.map.current.addSource('route', {
                    'type': 'geojson',
                    'data': {
                        'type': 'Feature',
                        'properties': {},
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': coordinatesRev
                        }
                    }
                });

                this.map.current.addLayer({
                    'id': 'route',
                    'type': 'line',
                    'source': 'route',
                    'layout': {
                        'line-join': 'round',
                        'line-cap': 'round',
                        'visibility': 'visible'
                    },
                    'paint': {
                        'line-color': '#888',
                        'line-width': 6
                    }
                });
            }
            for (const feature of pointersList.features) {
                /* new mapboxgl.Marker(
                    <div className="markerCognolo"
                        style={{
                            width: '5rem',
                            height: '5rem',
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }} />
                )
                    .setLngLat(feature.geometry.coordinates)
                    .addTo(map.current)
                console.log(feature.geometry.coordinates) */
                // Create a DOM element for each marker.
                const el = document.createElement('div');
                const width = feature.properties.iconSize[0];
                const height = feature.properties.iconSize[1];
                el.className = 'marker';
                el.style.display = "block";
                el.style.backgroundImage = `url(../../../logos/markerTest4.png)`;
                el.style.width = `${width}px`;
                el.style.height = `${height}px`;
                el.style.backgroundSize = '100%';
                el.style.backgroundRepeat = "no-repeat";
                el.style.borderRadius = 0;

                new mapboxgl.Marker(el)
                    .setLngLat(feature.geometry.coordinates)
                    .addTo(this.map.current);

            }
        })
    }
    /* changeSmall = () => {
      this.setState({ size: "small", message: "small" });
      ref={this.myRef} 
    }; */

    render() {
        return (
            <div className="section" key={this.props.keyChild}>
                <div ref={this.mapContainer} className="map-container" />
            </div>
        );
    }
}