import { graphql, useStaticQuery } from "gatsby"
import React from "react"

export const useMappaTracks = () => {
  const {
    allWp: { edges},
  } = useStaticQuery(graphql`
    query tracksDove {
        allWp {
            edges {
              node {
                doveSettings {
                  dove_contenuti {
                    mappa {
                      bearing
                      coordinate
                      coordinatePercorso
                      fieldGroupName
                      nome
                      pitch
                      descrizione
                      trackGpx {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
    }
  `)

  return edges[0].node.doveSettings.dove_contenuti.mappa
}