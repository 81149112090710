import styled from "styled-components"


export const MapModuleStyle = styled.div`
.wrapper-descrzione {
  background-color: var(--primary);
}

/* .holderList {
  padding: 0 var(--borderSpacing); 
} */
.tip {
  font-size: 13px;
  z-index: 9999999;
  position: absolute;
  right: calc(50% - 130px);
}

.descrizione-track{
  p {
   font-size: 24px;
  }
}
.map-container-wrapper {
  position:relative;
  overflow:hidden;
  padding: 0 var(--borderSpacing) 0 var(--borderSpacing);
  
  @media (max-width: 991px) {
    padding: 0 
  }
  
}
.currentTrack {
/*   position: absolute;
  top: 10px;
  left: 10px; */
  z-index: 2;
  padding: 3px 10px;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  background: rgba(255,255,255,0.8);
  border-radius: 8px;
  font-weight: 700;

  @media (max-width: 576px) {
    padding: 0 20px;
  }

  .desc a {
    color: rgba(0,0,0,0.4);
  }

  .info_currentTrack {
    display:flex;
    align-items: center;
    font-size:32px;
    padding-bottom: 3px;
    border-bottom: 2px solid;
    svg {
      font-size: 30px;
    }
    @media (max-width: 576px) {
      font-size:18px;
      line-height: 20px;
      svg {
        font-size: 27px;
      }
    }
    span {
      margin-left:5px;
     

      
    }
  }
  
}

.label-track {
  text-align: justify;
  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

p {
  text-align: justify;
  overflow:hidden;
  @media (max-width: 576px) {
    padding: 0 20px;
  }
}

.list-track {
  display: flex;
  flex-direction: column;
  z-index: 2;
  max-width: 600px;
  /* position: absolute;
  top: 90px; 
  left: 10px; */
  overflow:hidden;
  padding: 10px;
  border-radius: 6px;
  
  .label-list-track {
    margin-bottom: 10px;
    span {
      border-bottom: 1px solid;
      font-size: 14px;
    }
  }

  .item {
    position:relative;
      > div{
        position:relative;
      } 
      button {
        background: rgb(255, 255, 255,0.8);
        padding: 3px 6px;
        border-radius: 6px;
        border: 0;
        margin-bottom: 5px;
        text-align: left;
        max-width: fit-content;
      }
      button.active{
        background: rgb(0, 0, 0,0.8);
        font-weight: 700;
        color:#fff;
      }
    }
}
.openList.active {
  /* background: rgb(0, 0, 0,0.8);
  padding: 0 3px;
  span {
    color: rgb(255, 255, 255,1);
  } */
}

.openList, .goDesc {
  /* position: absolute;
   
    top: 10px;
    left: 85px;
    z-index: 2; */
    background:rgb(255, 255, 255,0.0);
    border-radius: 6px;
    display: flex;
    align-items: center;
    border: 0;
    padding: 0px;
    margin: 0px;
    svg {
     /*  width: 20px;
      height: 20px; */
      padding:0;
      margin:0;
      border:0;
      border-radius:0;
      width:18px
    }
    span {
      padding:3px;
      font-weight:700;
    }
}

.goDesc {
  a {
    color:black;
    text-decoration:none;
  }
}

.show {
  display:flex;
  align-items:center;
  padding:3px;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.hide {
  display:none;
}

#trackMenu {
  position: absolute;
  z-index: 999;
  right: 15px;
  top: 15px;
  width:100px;
  height:100px;
  background:#fff;
  cursor:pointer;
}

.introDesc {
  text-align: justify;
  @media (max-width: 576px) {
    padding: 0 20px;
  }
    
}

.infoMap {
  position:relative;
  .show {
    transition: all 2s ease-out;
    background:white
    svg {
      transition: all 2s ease-out;
    }
  }
  .blink .show {
    animation: blinker 2s linear 3;
     svg {
      animation: blinkerColor 2s linear 3;
    } 
   
  }
  
  @keyframes blinker {
    50% {
      /* background:rgb(255, 196, 0) */
      background:black
    }
  }
  @keyframes blinkerColor {
    50% {
      fill: white;
    }
  }


  .info-trigger {
    /* width: 40px; */
    /* height: 40px; */
    background: transparent;
    border: 0;
    position: absolute;
    z-index: 2;
    left: 5px;
    top: 5px;

    svg {
      width: 35px;
      height: 35px;
    }
    
    span {
      font-size: 24px;
      text-transform: capitalize;
    }
  }
 
  hr {
    margin: 15px 0;
  }
  .wrap-list-info {
    position: absolute;
    z-index: 2;
    top: 65px;
    border-radius: 10px;
    overflow:hidden;
      .list-info {
      a.info-single{
        color:var(--bs-body-color);
      }
      .info-single.centra {
        cursor: pointer;
        * {
          pointer-events: none;
        }
      }
      .info-single:first-child {
        padding-top:20px
      }
      .info-single:last-child {
        padding-bottom:20px
      }
      .info-single {
        padding: 5px 15px;
        background: rgba(255,255,255,0.95);
        //border: 1px solid rgba(0,0,0,0.2);
        
        > div {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ccc;
          font-weight: 700;
          font-size: 13px;
          svg {
            margin-right: 10px;
            height: 20px;
            width: 20px;
          }
        }
        span {
          font-size: 14px;
          margin-left: 30px;
          display: block;
        }
      }
    }
  }
}
`

