import React, { useContext, useState, useEffect } from "react"
import { motion } from "framer-motion"
import { GiBarefoot, GiPathDistance } from "react-icons/gi"
import { BiLineChart, BiShowAlt, BiHide } from "react-icons/bi"
import { AiOutlineClockCircle, AiOutlineColumnHeight } from "react-icons/ai"
import { MdOutlineFilterCenterFocus, MdOutlineDescription } from "react-icons/md"
import { ImFolderDownload } from "react-icons/im"

import { FiInfo } from "react-icons/fi"
import EventBus from "../Event/EventBus";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const InfoMap = (prop) => {
    //const featuredProduct = useFeaturedProduct()
    //const [isSmall, setIsSmall] = useState(UseMediaQuery('(min-width: 360px)'))
    //const [isOpen, setIsOpen] = useState(UseMediaQuery('(min-width: 360px)'))
    const [isOpen, setIsOpen] = useState(prop.isOpenProp)
    const [isDirect, setIsDirect] = useState(false)
    const breakpoints = useBreakpoint();
  
    //console.log("breakpointsStart",breakpoints)
    
    //const matchPoints = useBreakpoint(queries);
        
    const infoAnim = {
        open: {
            left: 10,
        },
        closed: {
            left: -600,
        },
    }

    const toggleOpen = function () {
        /* console.log("toggleOpen")
        console.log("toggleOpen ", prop) */
        setIsOpen((isOpen) => !isOpen)
        setIsDirect(true)
        EventBus.dispatch("open_info", { isOpen:!isOpen});
    };

    const clickCenter = function (e) {
        //console.log("dispatch centra_mappa", e.target.dataset.coord)
        EventBus.dispatch("centra_mappa", { centra_mappa: true, numTrack:e.target.dataset.track, map_coord: e.target.dataset.coord.split(",") });
    }

 

    useEffect(() => {
        //console.log("matchPoints",matchPoints);

       /*  EventBus.on("flyend", function (data) {
            console.log("On flyend",data);
            
            onEventEnd(data.flyend);
            setIsDirect(false)
            setIsOpen(data.flyend) 
        }) */

        /* if(isSmall){
            setIsOpen(false)
        } */

        return () => {
            console.log("infoMap UNMOUNT")
            //EventBus.remove("flyend");
        }
        /* window.addEventListener("scroll", handleScroll); */

    }, []);

    /* useEffect(() => {
        console.log("info MOUNT") 
         EventBus.on("changeBgMenu", function (data) {
           if (!isOpen || isOpen && !data.background) {
             setBackground(data.background)
           }
         })
         return () => {
           console.log("nav UNMOUNT")
           EventBus.remove("scrollEvent");
         }
       }, []); */

    return (
        <div className="infoMap" key="infoMap">
            
            <button
                key="infoMapBtnOpen"
                initial="closed"
                animate={
                    isOpen ? "open" : "closed"
                }
                onClick={toggleOpen}
                onKeyDown={toggleOpen}
                
                aria-label={isOpen ? "Close Menu" : "Open Menu"}
                className={isOpen ? " open info-trigger d-flex align-items-center" : "info-trigger d-flex align-items-center"}
            >
               <div className={isDirect? "": ""} >
                <div className={isOpen ? "hide" : "show" }>
                    <BiShowAlt />
                </div>
                <div className={isOpen ? "show" : "hide"}>
                    <BiHide  />
                </div>
                </div>
                <span className="ms-1">{isOpen ? "" : ""} informazioni</span>

            </button>
            
            <motion.div
                key="bg"
                initial="open"
                animate={isOpen ? "open" : "closed"}
                variants={infoAnim}
                transition={{ type: "ease", stiffness: 50, velocity: 50 }}
                className="wrap-list-info"
            >
                <div className="list-info">
                    <div className="info-single" key="dif"><div><BiLineChart /> DIFFICOLTA’:</div> <span>{prop.data.difficolta}</span></div>
                    <div className="info-single" key="tip"><div><GiPathDistance />TIPOLOGIA:</div> <span>{prop.data.tipologia} </span></div>
                    <div className="info-single" key="disl"><div><AiOutlineColumnHeight />DISLIVELLO:</div> <span>{prop.data.dislivello}</span></div>
                    <div className="info-single" key="dur"><div><AiOutlineClockCircle />DURATA:</div> <span>{prop.data.durata.split(',').map((step) => <div key={Math.random()}>{step}{"\n"}</div>)}</span></div>
                    <div className="info-single" key="dis"><div><GiBarefoot />DISTANZA:</div> <span>{prop.data.distanza.split(',').map((step) => <div key={Math.random()}>{step}{"\n"}</div>)}</span></div>
                    <div className="info-single centra" key="cen" data-track={prop.numTrack} data-coord={prop.data.centerPos.join(",")} onClick={clickCenter}><div><MdOutlineFilterCenterFocus />CENTRA:</div> <span>clicca</span></div>
                    {/* <div className="info-single" key="help"><div><IoMdHelpCircle />TIP:</div> <span>Tasto control + mouse<br/> per inclinare la visuale  </span></div> */}
                    <AnchorLink className="info-single d-block" to="/dove#descrizione" title="Our team">
                        <div><ImFolderDownload />SCARICA:</div> <span>il percorso in formato kml e gpx</span>
                    </AnchorLink>

                </div>
            </motion.div>
        </div>

    )
}

export default InfoMap
