import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import { graphql } from 'gatsby'
import PerksModuleDove from "../components/PerksModule/PerksModuleDove"
import Perk from "../components/PerksModule/Perk"
import MapModule from "../components/map/mapModule"
import { HiOutlineFolderDownload } from "react-icons/hi"


export default function DovePage({ data,location }) {

 
  /* const map = useMap()
  console.log('map center:', map.getCenter()) */

  const titles = ["Come raggiungere il rifugio Cognolo", "Rifugio Cognolo", "Rifugio Cognolo", "Rifugio Cognolo"]
  const subtitles = ["Scopri tutti i sentieri, leggi le loro descrizioni e scarica le tracce gps", "Un luogo dove fare esperienza e vivere la relazione con la natura in maniera diversa", "È raggiungibile solo a piedi con minimo 2 ore di cammino", "È aperto tutto l'anno solo su prenotazione"]
  const linkTexts = ["approfondisci", "Scopri le attività", "Come raggiungerci", "Prenota"]
  const linkUrls =["#approfondisci","#","#","#"]

  const position = [51.505, -0.09]
  return (
    <>
      <Seo title="Come Raggiungerci" />
      <Layout>
        {<BannerModuleWrapper
          max={0}
          titles={titles}
          subtitles={subtitles}
          linkTexts={linkTexts}
          linkUrls={linkUrls}
          images={data.allWp.edges[0].node.cognoloSettings.backgrounds_dove.bgsDove}
        />}

        <PerksModuleDove >
          <Perk title="The Title" content="The content" />
        </PerksModuleDove>
        <div className="section" id="approfondisci">
          <div className=" mb-0 container">

            <div className="">
              <div className="row d-flex align-items-center">
                <div className="col-lg-12">
                  <div className="border-bottom mb-4 me-4">
                    <h6>Il rifugio è raggiungibile attraverso diversi sentieri partendo dai paesi che costeggiano la catena dei Monti Sabini.</h6>
                  </div>
                  <h6>Puoi scaricare tutti i sentieri in formato gpx o kml <a className="download_link" href="./../rifugio_cognolo_gpx_kml.zip"> qui <HiOutlineFolderDownload/></a> </h6>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div className="wrap-map">
          <MapModule />
        </div>
      </Layout>
    </>
  )
}



export const imagesWp = graphql`
{
  allWp {
    edges {
      node {
        cognoloSettings {
          pageSlug
          pageTitle
          backgrounds_dove  {
            bgsDove {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.7
                    placeholder: BLURRED
                    formats: AUTO
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`