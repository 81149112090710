import React, { useRef, useEffect, useState, useContext } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css'
import InfoMap from './infoMap.js'
import MapboxPitchToggleControl from '@watergis/mapbox-gl-pitch-toggle-control';
import '@watergis/mapbox-gl-pitch-toggle-control/css/styles.css';
import ListTracks from './listTracks.js'
import { track_1, track_2, track_3 } from "./TrackConstants"
import { FiMap } from "react-icons/fi"
import EventBus from "../Event/EventBus";
import { useMappaTracks } from "../../hooks/useTracksMappa"
import { IoMdHelpCircle } from "react-icons/io"
import { AiOutlineCheck } from "react-icons/ai"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
//import gpxParser from "gpxparser"
//import parseKML from "parse-kml"

//GiBarefoot, BiLineChart , GiPathDistance , RiPinDistanceLine , RiPinDistanceFill , AiOutlineClockCircle , HiOutlineClock

mapboxgl.accessToken = 'pk.eyJ1IjoidG9kYmVydHV6emkiLCJhIjoiY2wxYXJxYzA1MDlmcTNjcDhpZmV1bmNjcyJ9.KNvXN5O-p1xzhN6R5GKphg';

export default function MapOpenMap(data) {
    console.log("INit")
    const mapContainer = useRef(null);
    const map = useRef(null);
    const breakpoints = useBreakpoint();
    //const tracksAlt = [track_1, track_2, track_3];
    let tracks = [];
    let tracksName = [];
    let tracksDesc = [];
    let mapInitialized = false;
    /* const [lng, setLng] = useState(12.7166212);
    const [lat, setLat] = useState(42.3531844);
    const [zoom, setZoom] = useState(13); */

    const [numTrack, setNumTrack] = useState(1);
    //const [triggerEnd, setTriggerEnd] = useState(false);

    let disableListener = false;

    //const { descrizione } = useMappaTracks()
    const tracksData = useMappaTracks();
    //console.log("tracksDatatracksData", tracksData)
    tracksData.forEach(element => {
        //console.log("tracksData ",element)
        /* if(element.trackGpx.localFile && element.trackGpx.localFile.absolutePath){
            let trackKML = parseKML
            .readKml('./path-to-file/file-name.kml')
            .then(console.log)
            .catch(console.error)

            
            
        } */
        if (element.coordinatePercorso) {
            tracksName.push(element.nome);
            tracksDesc.push(element.descrizione);
            // console.log("element.coordinatePercorso",element.nome,element.coordinatePercorso)

            tracks.push(
                {
                    bearing: element.bearing,
                    info: {
                        "difficolta": "Escurionistica",
                        "tipologia": "anello",
                        "dislivello": "500 mt +/-",
                        "durata": "6h ritorno",
                        "distanza": "12 km",
                        "centerPos": [
                            12.7048,
                            42.3543
                        ],
                    },
                    name: element.nome,
                    pitch: element.pitch,
                    track: JSON.parse(element.coordinatePercorso.replace(/(\r\n|\n|\r)/gm, "")),
                    zoom: 14,
                    //coord: element.coordinate.split(",")
                }
            )
        }
    });
    //console.log(tracksData, "tracksData", " tracksName", tracksName, " tracksDesc", tracksDesc)
    const mapRange = function (value, oldRange, newRange) {
        var newValue = (value - oldRange[0]) * (newRange[1] - newRange[0]) / (oldRange[1] - oldRange[0]) + newRange[0];
        return Math.min(Math.max(newValue, newRange[0]), newRange[1]);
    }

    // mapOpenMap.js:150 12.7128

    function trackChange(e) {
        //console.log('SetTrack ', e.target.dataset.track);
        setNumTrack(e.target.dataset.track);

        addTRack(tracks[e.target.dataset.track - 1].track, tracks[e.target.dataset.track - 1]);

        //console.log(tracks[e.target.dataset.track - 1].pos_center)

    }

    //SEGNALINO COGNOLO
    const markerJson = {
        type: 'FeatureCollection',
        features: [
            {
                type: 'Feature',

                geometry: {
                    type: 'Point',
                    coordinates: [12.71694569, 42.352336]
                    //coordinates: [12.71694569, 42.352536]
                },
                properties: {
                    iconSize: [54, 140],
                    title: 'Mapbox',
                    description: 'Washington, D.C.'
                }

            },

        ]
    };

    function addTRack(coordinates, trackInfo) {

        console.log("ADD ROUTE ", trackInfo)
        if (map.current.getSource("route")) {
            console.log("removeSource");
            map.current.removeLayer("route");
            map.current.removeSource("route");
        }
        map.current.addSource('route', {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'properties': {},
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coordinates
                }
            }
        });

        map.current.addLayer({
            'id': 'route',
            'type': 'line',
            'source': 'route',
            'layout': {
                'line-join': 'round',
                'line-cap': 'round',
                'visibility': 'visible'
            },
            'paint': {
                'line-color': '#888',
                'line-width': 5
            }
        });
        const padding = {}
        padding["left"] = breakpoints.sm ? 20 : 270;
        padding["left"] = 50;
        console.log("numTrack", numTrack)
        fittaMap(coordinates, trackInfo, padding)
        console.log("Dispatch flyeend false")
        disableListener = true;
        EventBus.dispatch("flyend", { flyend: false });
    }

    function fittaCenterMap(numTrackInner) {
        console.log("numTrackInner", numTrackInner)
        let coordinates = tracks[numTrackInner - 1].track;
        let trackInfo = tracks[numTrackInner - 1];
        const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
            bounds.extend(coord);
        }
        console.log("bounds", bounds, trackInfo)

        map.current.fitBounds(bounds, {
            padding: 20,
            pitch: 0,
            bearing: 0,
            //speed: mapRange(getCurrentZoom, [15, 1], [0.2, 1]), // make the flying slow

            //center: tracks[e.target.dataset.track - 1].coord,
            //zoom: trackInfo.zoom,
        });
        /* map.current.jumpTo({
            pitch: 0,
            bearing: 0, 
        });  */
    }

    function fittaMap(coordinates, trackInfo, padding) {
        console.log(coordinates[0], "||| ", coordinates[0],)
        const bounds = new mapboxgl.LngLatBounds(
            coordinates[0],
            coordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
            bounds.extend(coord);
        }
        console.log("bounds", bounds, breakpoints.sm)

        map.current.fitBounds(bounds, {
            padding: 20,
            //speed: mapRange(getCurrentZoom, [15, 1], [0.2, 1]), // make the flying slow
            //pitch: trackInfo.pitch,
            // bearing: trackInfo.bearing,  
            //center: tracks[e.target.dataset.track - 1].coord,
            //zoom: trackInfo.zoom,
        });
        /* map.current.easeTo({
            padding: padding,
        })  */
    }

    useEffect(() => {
        if (map.current) return; // initialize map only once
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            scrollZoom: false,
            touchZoomRotate: true,

            //style: 'mapbox://styles/mapbox/streets-v11',
            //center: [12.7166212, 42.3531844],
            pitch: 40, // pitch in degrees
            //bearing: -60, // bearing in degrees
            //zoom: 13,
            style: 'mapbox://styles/todbertuzzi/cl1ary226000j15phmkalhlzy', // custom style url from https://studio.mapbox.com/
            //style: 'mapbox://styles/mapbox/satellite-v9'
        });
        const scale = new mapboxgl.ScaleControl({
            maxWidth: 80,
            unit: 'metric'
        });

        map.current.addControl(
            new MapboxPitchToggleControl({
                pitch: 70,
                bearing: null,
                minpitchzoom: null
            }),
            "top-right"
        );
        /* map.current.addControl(scale); */

        map.current.addControl(new mapboxgl.NavigationControl());
        map.current.on('load', () => {
            if (!map.current.getSource('route')) {
                console.log("CISTO", tracksData[0])
                //console.log(data.coordinates.map(x => x.reverse()),tracks[0])
                //addTRack(data.coordinates.track,data.coordinates)
                if (tracksData[0].trackGpx && tracksData[0].trackGpx.mediaItemUrl) {
                   /*   console.log("VADO DI MPPA CARICATA", tracksData[0].trackGpx.mediaItemUrl)
                    map.current.addSource('earthquakes', {
                        type: 'geojson',
                        // Use a URL for the value for the `data` property.
                        data: "/capraro.geojson"
                    });
                    map.current.addLayer({
                        'id': 'earthquakes-layer',
                        'type': 'line',
                        'source': 'earthquakes',
                        'paint': {
                            'line-color': 'red',
                            'line-width': 15,
                        }
                    });  */
                } else {
                    //addTRack(tracks[numTrack - 1].track, tracks[numTrack - 1]);
                }
                addTRack(tracks[numTrack - 1].track, tracks[numTrack - 1]);


                mapInitialized = true;
            }
            for (const feature of markerJson.features) {
                /* new mapboxgl.Marker(
                    <div className="markerCognolo"
                        style={{
                            width: '5rem',
                            height: '5rem',
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }} />
                )
                    .setLngLat(feature.geometry.coordinates)
                    .addTo(map.current)
                console.log(feature.geometry.coordinates) */
                // Create a DOM element for each marker.
                const el = document.createElement('div');
                const width = feature.properties.iconSize[0];
                const height = feature.properties.iconSize[1];
                el.className = 'marker';
                el.style.display = "block";
                el.style.backgroundImage = `url(../../../logos/markerTest4.png)`;
                el.style.width = `${width}px`;
                el.style.height = `${height}px`;
                el.style.backgroundSize = '100%';
                el.style.backgroundRepeat = "no-repeat";
                el.style.borderRadius = 0;

                new mapboxgl.Marker(el)
                    .setLngLat(feature.geometry.coordinates)
                    .addTo(map.current);
            }

        })
        map.current.on('moveend', ({ originalEvent }) => {
            if (originalEvent) {
                map.current.fire('usermoveend');
            } else {
                //map.current.fire('flyend');
                if (!disableListener) {
                    console.log("dispatch flyend", numTrack)
                    EventBus.dispatch("flyend", { flyend: true });
                }
                disableListener = false;
            }
        });
        EventBus.on("centra_mappa", function (data) {
            //console.log("flyend ",prop)
            console.log("centra_mappa", data.centra_mappa)
            //map.current.flyTo({ center: data.map_coord, speed: 1 })
            fittaCenterMap(data.numTrack)
            //setIsOpen(data.flyend)
        })

        /* EventBus.on("open_info", function (data) {
            //console.log("flyend ",prop)
            console.log("open_info", data)
            const padding = {}
            padding["left"] = data.isOpen ? 270 : 0;
            console.log("disableListener,", disableListener, padding, "numTrack", numTrack)
            disableListener = true;
             map.current.easeTo({
                padding: 20,
            }) 
            //fittaMap(tracks[numTrack - 1].track, tracks[numTrack - 1],padding)

            //  map.current.flyTo({ center: data.map_coord, speed: 1 })
            //setIsOpen(data.flyend)
        }) */
        console.log("INIT MAP")
    }, []);

    // side effect numTrack
    useEffect(() => {
        console.log("OnChange numtracck", numTrack)
        if (mapInitialized) {
            console.log("mapInitialized");
        }
        /* if (mapInitialized) {
            
            let coordinates = tracks[numTrack - 1].track;
            let trackInfo = tracks[numTrack - 1];
            //console.log("onChange numTrack")
            if (map.current.getSource("route")) {
                console.log("removeSource");
                map.current.removeLayer("route");
                map.current.removeSource("route");
            }
            map.current.addSource('route', {
                'type': 'geojson',
                'data': {
                    'type': 'Feature',
                    'properties': {},
                    'geometry': {
                        'type': 'LineString',
                        'coordinates': coordinates
                    }
                }
            });

            map.current.addLayer({
                'id': 'route',
                'type': 'line',
                'source': 'route',
                'layout': {
                    'line-join': 'round',
                    'line-cap': 'round',
                    'visibility': 'visible'
                },
                'paint': {
                    'line-color': '#888',
                    'line-width': 5
                }
            });
            const padding = {}
            padding["left"] = breakpoints.sm ? 20 : 270;
            console.log("numTrack", numTrack)
            fittaMap(coordinates, trackInfo, padding)
        }  */
    }, [numTrack])

    useEffect(() => {
        //if (!map.current) return; // wait for map to initialize
        map.current.on('move', () => {
            /*   console.log("---------------")
             console.log("Coord ", map.current.getCenter().lng.toFixed(4), "  ", map.current.getCenter().lat.toFixed(4))
             console.log("Pitch ", map.current.getPitch().toFixed(4))
             console.log("Bearing ", map.current.getBearing().toFixed(4))
             console.log("Zoom ", map.current.getZoom().toFixed(4))  */
            /* setLng(map.current.getCenter().lng.toFixed(4));
            setLat(map.current.getCenter().lat.toFixed(4));
            setZoom(map.current.getZoom().toFixed(2));      */
        });

        //console.log("MOUNT MAP", numTrack)

        // add markers to map

    });


    return (
        <div>
            <div className=" mt-0 pt-0 container-fluid map-container-wrapper">
                <div className="debug">{data.random}</div>
                {/* {console.log("renderMapOpen")} */}
                {/* <div className="currentTrack"><FiMap/><span>{tracks[numTrack - 1].name}</span></div> */}
                {/* <div className="list-track">
                <button data-track="1" className={numTrack == 1 ? "active" : "not_active"} onClick={trackChange}>
                    Anello dei Monti Aspresi con Partenza da Santa Maria
                </button>
                <button data-track="2" className={numTrack == 2 ? "active" : "not_active"} onClick={trackChange}>
                    Da Poggio Perugino a Fonte Cognolo a/r
                </button>
                <button data-track="3" className={numTrack == 3 ? "active" : "not_active"} onClick={trackChange}>
                    Da Roccantica Via  Monte Pizzuto
                </button>
            </div> */}
                <ListTracks isOpen={false} onClick={trackChange} tracksName={tracksName} numTrack={numTrack} />
                <InfoMap numTrack={numTrack} data={tracks[numTrack - 1].info} isOpen={false} />
                <div ref={mapContainer} className="map-container" />
                <div className="info-single tip d-flex justify-content-center" key="help"><div><IoMdHelpCircle /></div> <span>Tasto ctrl + mouse per inclinare la visuale  </span></div>

            </div>
            <div id="descrizione" className="section section__padding_bottom pt-3 wrapper-descrzione">
                <div className="descrizione-track">
                    <div className="introDesc">
                        <div className="col-lg-12 text-lg-start pt-4">
                            <div className="intro-text-desc">Porta con te un'attrezzatura minima di:</div>

                            <dl className="mt-0 list-unstyled lista-equip mb-0 pb-0">
                                <AiOutlineCheck className="me-2" />
                                <li>scarpe da trekking,</li>
                                <AiOutlineCheck className="me-2" /><li>zaino,</li>
                                <AiOutlineCheck className="me-2" /><li>acqua,</li>
                                <AiOutlineCheck className="me-2" /><li>bussola,</li>
                                <AiOutlineCheck className="me-2" /><li>mappa,</li>
                                <AiOutlineCheck className="me-2" /><li>cibo,</li>
                                <AiOutlineCheck className="me-2" /><li>abbigliamento adeguato alla stagione,</li>
                                <AiOutlineCheck className="me-2" /><li>torcia,</li>
                                <AiOutlineCheck className="me-2" /><li>antipioggia/vento.</li>
                            </dl>

                        </div>
                    </div>
                    <h2 className="label-track mt-5 mb-3"
                        dangerouslySetInnerHTML={{ __html: tracksName[numTrack - 1] }}>
                    </h2>
                    <div className="desc"
                        dangerouslySetInnerHTML={{ __html: tracksDesc[numTrack - 1] }}>
                    </div>
                </div>
            </div>
        </div>
    );
}

