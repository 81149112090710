import React, { useContext, useState, useEffect } from "react"
import MapOpenMap from "./mapOpenMap"
import { MapModuleStyle } from "./mapModuleStyle"
import {track_1,track_2} from "./TrackConstants"
import OpenMapComp from  "./mapOpenMapComp"

const MapModule = () => {
  //const featuredProduct = useFeaturedProduct()

  
  const [track, setTrack] = useState(track_1)
  const [random, setRandom] = useState(0)

  
 

  

  
  return (
    
    <MapModuleStyle>
       <MapOpenMap  coordinates={track} keyChild={random}   className="section" />
      
    </MapModuleStyle>
  )
}

export default MapModule
