import React, { useContext, useState, useEffect } from "react"
import { motion } from "framer-motion"
import { CgMoreVerticalR } from "react-icons/cg"
import { AiFillCloseCircle } from "react-icons/ai"
import { RiArrowGoBackFill } from "react-icons/ri"
import { FiMap } from "react-icons/fi"
import { BsPlusCircle } from "react-icons/bs"
import { MdOutlineDescription } from "react-icons/md"


import EventBus from "../Event/EventBus";
import { AnchorLink } from "gatsby-plugin-anchor-links";

/* import { motion } from "framer-motion"
import { GiBarefoot, GiPathDistance } from "react-icons/gi"
import { BiLineChart, BiShowAlt, BiHide } from "react-icons/bi"
import { AiOutlineClockCircle, AiOutlineColumnHeight } from "react-icons/ai"
import { MdOutlineFilterCenterFocus } from "react-icons/md"
import { FiInfo } from "react-icons/fi"
import EventBus from "../Event/EventBus";
 */

const ListTracks = (prop) => {
 
    //const featuredProduct = useFeaturedProduct()
    const [isOpen, setIsOpen] = useState(prop.isOpenProp)
    const itemListAnim = {
        open: {
            left: 0,
        },
        closed: {
            left: -600,
        },
    }
    const lisTrackAnim = {
        open: {
            background: "rgba(255,255,255,0.45)",
            display: "block",
            height: "auto"
        },
        closed: {
            background: "rgba(255,255,255,0)",
            height: 0,
            transitionEnd: {
                height: 0,
            },
        },
    }
  

    const toggleOpen = function () {
        console.log("toggleOpen")
        setIsOpen((isOpen) => !isOpen)
    };

    return (
        <div className="holderList">
            <div className="currentTrack" >
                <div className="info_currentTrack" onClick={toggleOpen}>
                    <FiMap />
                    <span>{prop.tracksName[prop.numTrack - 1]}</span>
                </div>
                <div className="d-flex justify-content-between justify-content-md-start flex-column flex-md-row  w-100">
                    <button onClick={toggleOpen} className={isOpen ? "openList active" : "openList"}> <AiFillCloseCircle className={isOpen ? "show" : "hide"} /> <BsPlusCircle className={isOpen ? "hide" : "show"} />  <span>{isOpen ? "Scopri gli altri itinerari" : "Scopri gli altri itinerari"} </span></button>
                    <button className={"goDesc ms-md-4"}>  <MdOutlineDescription />  <AnchorLink className="info-single d-block" to="/dove#descrizione" title="Our team">
                        <span>Leggi la descrizione</span>
                    </AnchorLink></button>
                </div>
            </div>

            <motion.div
                initial="open"
                animate={isOpen ? "open" : "closed"}
                variants={lisTrackAnim}
                transition={{ type: "ease", stiffness: 50, velocity: 50, delay: 0 }}
                className="list-track"
            >
                <div className="item label-list-track">
                    <motion.div
                        initial="open"
                        animate={isOpen ? "open" : "closed"}
                        variants={itemListAnim}
                        transition={{ type: "ease", stiffness: 50, velocity: 50, delay: 0 }}
                        onClick={toggleOpen}
                    >
                        <span>Selezione un itinerario</span>
                    </motion.div>
                </div>
                {prop.tracksName.map((track, index) => (
                <div className="item" key={track}>
                    <motion.div
                        initial="open"
                        animate={isOpen ? "open" : "closed"}
                        variants={itemListAnim}
                        transition={{ type: "ease", stiffness: 50, velocity: 50, delay: 0 }}
                        onClick={toggleOpen}
                    >
                        <button data-track={(index+1)} className={prop.numTrack == (index+1) ? "active" : "not_active"} onClick={prop.onClick}>
                            {track}
                        </button>
                    </motion.div>
                </div>
                ))}
                
            </motion.div>
        </div>

    )
}

export default ListTracks
